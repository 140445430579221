import { render, staticRenderFns } from "./registerAccount.vue?vue&type=template&id=66c8f2c4&scoped=true&"
import script from "./registerAccount.vue?vue&type=script&lang=js&"
export * from "./registerAccount.vue?vue&type=script&lang=js&"
import style0 from "./registerAccount.vue?vue&type=style&index=0&id=66c8f2c4&prod&scoped=true&lang=less&"
import style1 from "./registerAccount.vue?vue&type=style&index=1&id=66c8f2c4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c8f2c4",
  null
  
)

export default component.exports